import './bootstrap';

$(function() {
    const globalSelection = $("#global_set_client");

    if (globalSelection.length) {
        axios.get(`/api/frontend/basic/clients?sort=business_name&page_size=99999&sort=business_name`, {}).then(function (content) {
            content.data.data.forEach((client) => {
                globalSelection.append(`<option value='${client.id}'>${$("<textarea/>").text(client.business_name).html()}</option>`);
            });
            window.dispatchEvent(new Event('clientListLoaded'));
        }).catch((e) => {
            console.log("Unable to fetch client list:", e);
        });

        globalSelection.on("change", function() {
            $(this).closest('form').submit();
        });
    }
});
